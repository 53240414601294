import React from 'react';
import styled from "styled-components";
import ExpertVideoItem from './ExpertVideoItem';
import Section from '../_global/layout/Section';
import Text from '../_global/editorial/Text';
import Spacer from '../_global/layout/Spacer';
import Block from '../_global/editorial/Block';

const Comp = styled.div`
    margin: 0 auto;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3,  minmax(0, 1fr));
    @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
        grid-template-columns: repeat(2, 1fr);
    }

@media (max-width: ${({ theme }) => theme.breakPoints.s}) {
    grid-template-columns: repeat(1, 1fr);
}
`

const ExpertVideos = (props) => {
    const { videos } = props

    const videoItems = videos ? videos.map((item, index) => {
        return <ExpertVideoItem data={item} index={index} key={index} />
    }) : null

    return (
        <Section m="medium">
            <Comp>
                {videoItems}
            </Comp>
        </Section>
    );
};

export default ExpertVideos;