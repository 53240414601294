import React from 'react';
import styled from "styled-components";
import { graphql } from "gatsby";
import Text from '../_global/editorial/Text';
import Spacer from '../_global/layout/Spacer';
import Link from '../_global/localization/Link';

const Comp = styled.div`
    display:flex ;
    flex-direction: column;
    flex: 1;
`

const Iframe = styled.iframe`
    aspect-ratio: 1.777777777777778;
    width: 100%;
`

const UList = styled.ul`
  list-style: disc;
  padding-left: 1rem;
`;

const LinkText = styled(Text)`
  width: fit-content;
`;

const TheLink = styled(Link)`
  text-decoration: underline;
  word-break: break-word;

  :hover {
    text-decoration: none;
  }
`;

const ExpertVideoItem = (props) => {
    const { data } = props

    console.log(data.links)

    const links = data.links.map((item, index) => {
        return (
            <li key={index}>
                <LinkText s="medium">
                    <TheLink to={item.url}>
                        {item.title}
                    </TheLink>
                </LinkText>
            </li>
        )
    })

    return (
        <Comp>
            <Iframe src={data.source} allowFullScreen></Iframe>
            <Spacer s="small" />

            <Text s="medium" w="bold">
                {data.title}
            </Text>

            <Text s="medium">
                {data.description}
            </Text>
            <Spacer s="small" />

            <UList>
                {links}
            </UList>
        </Comp>
    );
};

export default ExpertVideoItem;

export const query = graphql`
    fragment ExpertVideoFragment on SanityExpertVideo {
        description {
          _type
          fr
        }
        source
        title {
          _type
          fr
        }
        links {
            url
            title {
                _type
                fr
            }
        }
    }
`;