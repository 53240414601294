import React, { useState } from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Block from "../components/_global/editorial/Block";
import Spacer from "../components/_global/layout/Spacer";
import ExpertVideos from "../components/12_expertVideo/ExpertVideos";

const Experts = ({ data, ...props }) => {
    const { page } = data;
    const videoItems = data.allSanityExpertVideo.nodes;

    return (
        <Layout title={page.common.title}>
            <PageHeader title={page.common.title} />

            <Section m="medium">
                <Block data={page.text} />
                <Spacer s="large" />
            </Section>

            <Spacer s="medium" />
            <ExpertVideos
                videos={videoItems}
            />
            <Spacer s="large" />
            <Spacer s="large" />
        </Layout>
    );
};

export default localize(Experts);

export const query = graphql`
    {
        page: sanityExperts {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            text {
                ...CompleteBlockFragment
            }
        }
        allSanityExpertVideo(sort: {fields: title___fr, order: ASC}) {
            nodes {
                ...ExpertVideoFragment
            }
        }
    }
`;